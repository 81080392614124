import Expo2012Content from "../../components/Events/Expo2012Content";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

export default function Expo2012() {
    return (
        <>
            <Navbar />
            <Expo2012Content />
            <Footer />
        </>
    )
}