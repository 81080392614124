import Footer from "../../components/Footer";
import SaarBiotechContent from "../../components/ManufacturingUnit/SaarBiotechContent";
import Navbar from "../../components/Navbar";

export default function SaarBiotech() {
    return (
        <>
            <Navbar />
            <SaarBiotechContent />
            <Footer />
        </>
    )
}