import { useEffect } from "react";
import AboutContent from "../components/AboutContent";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function About() {
    useEffect(() => {
        document.title = "About Us - Saar Biotech"
    }, []);

    return (
        <>
            <Navbar />
            <AboutContent />
            <Footer />
        </>
    )
}