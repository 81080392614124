import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

export default function Product() {
    return (
        <>
            <Navbar />
            <Footer />
        </>
    )
}