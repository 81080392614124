import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import styles from './Banner.module.css';
import { LoadingButton } from "@mui/lab";
import { Link } from 'react-router-dom';

function Item(props) {

    return (
        <Paper className={`${styles.carouselItem}`}>
            <div className={styles.imageContainer}>
                <img src={props.item.image} alt="Recode7" className={styles.image} />

                <div className={`${styles.textContainer} px-5`}>
                    {/* <Container maxWidth="md" sx={{ backgroundColor: 'rgba(255,255,255,0.6)', py: 5, borderRadius: '10px' }}> */}
                    <h1 className={`${styles.title} text-dark`} >{props.item.heading1}</h1>
                    <h1 className={`${styles.title} mb-4`} style={{ color: '#EF3E00' }}>{props.item.heading2}</h1>
                    {/* <h5 className={`${styles.description} text-dark mb-5`}>{props.item.description}</h5> */}

                    <Link href="/about">
                        <LoadingButton
                            color="primary"
                            size='large'
                            type="submit"
                            variant="contained"
                            loading={false}
                            sx={{
                                background: "#EF3E00",
                                color: "white",
                                border: "2px solid #EF3E00",
                                borderRadius: "25px",
                                fontWeight: "600",
                                fontFamily: "Montserrat, sans-serif",
                                fontSize: "calc(0.6rem + 0.5vw)",
                                transition: "0.5s",
                                '&:hover': {
                                    background: "#EF3E00",
                                    color: "white",
                                },
                            }}
                        >
                            LEARN MORE
                        </LoadingButton>
                    </Link>
                    {/* </Container> */}
                </div>
            </div>
        </Paper >
    )
}

const Banner = () => {
    var items = [
        {
            heading1: "Discovering Hope in",
            heading2: "Every Dose",
            image: "/images/banner3.jpg"
        },
        {
            heading1: "Your Health is always",
            heading2: "Our Priority",
            image: "/images/banner2.jpg"
        },
        {
            heading1: "From Research",
            heading2: "to Recovery",
            image: "/images/banner1.jpg"
        }
    ]

    return (
        <Carousel height={'60vh'} animation='fade' duration={1000} interval={5000} indicators={false}>
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}

export default Banner;
