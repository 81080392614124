import { Container, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { FiberManualRecord } from "@mui/icons-material";

export default function IronSpray() {
    return (
        <>
            <Navbar />


            <Toolbar />
            <Typography variant="h4" sx={{ color: '#EF3E00', textAlign: 'center' }}>Iron Oral Spray</Typography>
            <Toolbar />

            <Container maxWidth="md">
                <img src="/images/iron-oral-spray.jpg" alt="Saar Biotech" style={{ width: '100%' }} />

                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2, mt: 5 }}>
                    Indications:- Avoiding digestive comfort and enhancing bioavailability
                </Typography>

                <Typography variant="h6" sx={{ color: '#EF3E00', mb: 2.5, mt: 4, textTransform: 'uppercase' }}>
                    Approved Name - <span style={{ color: 'black' }}>Iron Oral Spray</span>
                </Typography>

                <Typography variant="button" sx={{ fontSize: '1.125rem' }}>
                    Spray Presentation:
                </Typography>

                <Typography sx={{ fontSize: '1.125rem' }}>
                    Iron (As Ferric ammonium citrate) 4.2 mg
                </Typography>

                <Typography sx={{ my: 3 }}>
                    Iron oral spray is an effective intra-spray technology formulated to directly deliver iron into the bloodstream, bypassing the primary processing by the digestive system and thus preventing digestive discomfort experienced from ingested iron supplements while also enhancing bioavailability. The tiny droplets absorb quickly, providing fast nutrient uptake.
                </Typography>

                <Typography variant="h6" sx={{ color: '#EF3E00', mt: 4, textTransform: 'uppercase' }}>
                    Indications
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Prevents digestive discomfort
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Reduces tiredness and fatigue
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Increases bioavailability
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Effective alternative to tablets and capsules
                        </ListItemText>
                    </ListItem>
                </List>

                <Typography variant="h6" sx={{ color: '#EF3E00', mt: 4, textTransform: 'uppercase' }}>
                    Main Features
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Treats Anemia
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Boosts Hemoglobin
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Reduces Fatigue
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Increases Muscle Strength
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Boosts Immunity
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Improves Concentration
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Reduces Bruising
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Restores Sleep
                        </ListItemText>
                    </ListItem>
                </List>

                <Typography variant="h6" sx={{ color: '#EF3E00', mb: 2.5, mt: 4, textTransform: 'uppercase' }}>
                    Description
                </Typography>

                <Typography>
                    Iron is an essential mineral. Most of the iron in the body is found in the hemoglobin of red blood cells and in the myoglobin of muscle cells. Iron is vital for transporting oxygen and carbon dioxide. It is highly regarded for its role in supporting a healthy immune system, treating anemia, boosting hemoglobin, reducing tiredness and supporting a normal cognitive function.
                </Typography>

                <Typography variant="h6" sx={{ color: '#EF3E00', mt: 4, textTransform: 'uppercase' }}>
                    Storage
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            tore at room temperature and out of direct sunlight.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Keep out of reach of children.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord sx={{ fontSize: "small", color: '#EF3E00' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Once opened, use within 6 months.
                        </ListItemText>
                    </ListItem>
                </List>

                <Toolbar />

                <Typography sx={{ fontStyle: 'italic', fontWeight: 600 }}>
                    Disclaimer: The data uploaded is made from content already available on internet. The company holds no right of it and is not responsible for any wrong information.
                </Typography>

                <Toolbar />
            </Container>
            <Footer />
        </>
    )
}