import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SaarBiotech from "./pages/ManufacturingUnit/SaarBiotech";
import DmPharma from "./pages/ManufacturingUnit/DmPharma";
import Pharmac from "./pages/Events/Pharmac";
import Expo2012 from "./pages/Events/Expo2012";
import Expo2013 from "./pages/Events/Expo2013";
import VitaminD3Drops from "./pages/Products/VitaminD3Drops";
import ImmunitySpray from "./pages/Products/ImmunitySpray";
import IronSpray from "./pages/Products/IronSpray";
import MultiVitaminSpray from "./pages/Products/MultiVitaminSpray";
import Product from "./pages/Products/Product";

export default function AppRoutes() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/manufacturing-unit/saar-biotech" element={<SaarBiotech />} />
                    <Route path="/manufacturing-unit/dm-pharma" element={<DmPharma />} />
                    <Route path="/events/pharma-south-2014" element={<Pharmac />} />
                    <Route path="/events/indian-pharma-expo-2012" element={<Expo2012 />} />
                    <Route path="/events/indian-pharma-expo-2013" element={<Expo2013 />} />

                    <Route path="/products" element={<Product />} />
                    <Route path="/products/vitamin-d3-drops" element={<VitaminD3Drops />} />
                    <Route path="/products/immunity-booster-spray" element={<ImmunitySpray />} />
                    <Route path="/products/iron-oral-spray" element={<IronSpray />} />
                    <Route path="/products/multivitamin-oral-spray" element={<MultiVitaminSpray />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}  