import { useEffect } from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import HomeContent from "../components/HomeContent";
import Navbar from "../components/Navbar";

export default function Home() {
    useEffect(() => {
        document.title = "Saar Biotech"
    }, []);

    return (
        <>
            <Navbar />
            <Banner />
            <HomeContent />
            <Footer />
        </>
    )
}