import PharmacContent from "../../components/Events/PharmacContent";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

export default function Pharmac() {
    return (
        <>
            <Navbar />
            <PharmacContent />
            <Footer />
        </>
    )
}